jQuery(function($){

  const _url  = new URL(location);
  const _host = _url.hostname;
  const _root = document.documentElement;

  // 最大表示件数
  _max_more = 500;

  //キャッシュなしの設定
  $.ajaxSetup({ cache: false });

  _create_primary($);
  _create_more($);
});

$.easing.easeOutCirc = function (x, t, b, c, d) {
  return c * Math.sqrt(1 - (t=t/d-1)*t) + b;
};
$.easing.easeInOutExpo = function(x, t, b, c, d) {
  if (t==0) return b;
  if (t==d) return b+c;
  if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
  return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
};


let _create_primary = ($) => {
  let _articleListWrap = $('.article-list[data-targets]');

  if ( _articleListWrap.length > 0 ) {
    _articleListWrap.each( (i, list) => {
      $(list).attr('data-list-id', 'list'+i);
      var _listID   = $(list).data('list-id');
      var _targets  = $(list).data('targets');
      var _thisAttr = $(list).data('select').split('|');
      var _num      = (_thisAttr[0]) ? _thisAttr[0] : '10';
      var _offset   = (_thisAttr[1]) ? _thisAttr[1] : '0';
      var _insertAD = (_thisAttr[4]) ? _thisAttr[4] : '';
      var _imgPattern  = (_thisAttr[5]) ? _thisAttr[5] : 'e';

      _article_list($, _targets, _num, _offset, _insertAD, _listID, _imgPattern);
    });
  }

  // create more btn
  _articleListWrap.each( (i, more) => {
    if( $(more).data('select').split('|')[3] !== '' && $(more).data('select').split('|')[2] > 0) {
      $(more).after('<div class="btn-wrap"><div class="btn listmore">'+ $(more).data('select').split('|')[3] +'</div></div>');
    }
  });
}

let _create_more = ($) => {
  $('.article-list[data-targets]').next('.btn-wrap').find('.btn.listmore').each( (i, btn) => {
    // var n = ( $(btn).parent().prev('.article-list[data-targets]').data('select').split('|')[1] > 0 ) ? 1 : 0;
    var n = 1;


    $(btn).on('click', (cl) => {
      var _this     = $(cl.currentTarget).parent().prev('.article-list[data-targets]');
      var _listID   = _this.data('list-id');
      var _targets  = _this.data('targets');
      var _thisAttr = _this.data('select').split('|');
      var _insertAD = (_thisAttr[4]) ? _thisAttr[4] : '';
      var _imgPattern  = (_thisAttr[5]) ? _thisAttr[5] : 'e';

      var _adNum    = (_thisAttr[4]) ? _thisAttr[4].split(',').length : '';
      var _num      = (_thisAttr[2]) ? _thisAttr[2] : _thisAttr[0];

      if (n == 1) {
        _offset = Number(_thisAttr[0]) + Number(_thisAttr[1]);
      } else {
        _offset = Number(_thisAttr[0]) + Number(_thisAttr[1]) - Number(_num) + Number(n * _num);
      }

      n++;

      _article_list($, _targets, _num, _offset, _insertAD, _listID, _imgPattern);

      //window.setTimeout( () => {// 最新の追加分へスクロール
      //  (() => {
      //    $('body,html').animate({ scrollTop: Math.floor( $('.currentPos', _this).offset().top ) + 'px' }, 1000, 'easeInOutExpo');
      //  })();
      //}, 300);

      // もっとみる施行回数 発行
      var _storageItemName = _targets.split('/').join('-').split(',').join('-');
      sessionStorage.setItem(_storageItemName, n);
    });

    var _targets = $(btn).parent().prev('.article-list[data-targets]').data('targets');
    var _listID = $(btn).parent().prev('.article-list[data-targets]').data('list-id');
    var _storageItemName = _targets.split('/').join('-').split(',').join('-');


    // もっとみる回数があったらそこまで展開
    var _create_more_now = ( sessionStorage.getItem(_storageItemName) ) ? sessionStorage.getItem(_storageItemName) : 0;
    if ( _create_more_now > 0 ) {
      var fn = function() {
        if (i >= _create_more_now) {
          clearInterval(_add);
        } else {
          $(btn).trigger('click');
        }
        i++;
      }
      var i = 1;
      var _add = setInterval(fn,0);
    }

  });
}

let _article_list = ($, _targets, _num, _offset, _insertAD, _listID, _imgPattern) => {

  let _targetPath = _targets.split(',');
  let requests = [];
  for( let i = 0; i < _targetPath.length; i++ ){
    request = _targetPath[i];
    if (request.indexOf('contentslink') != -1) {
      request = { path: request + '/contentslink_list.json?request='+request };
    } else if(request.indexOf('news/paper') != -1) {
      request = { path: request + '.json?request='+request };
    } else if(request.indexOf('news/sports/koya/photo') != -1) {
      request = { path: request + '/LibraryFiles/article_list_KEP1S00V.json?request='+request };
    } else {
      request = { path: request + '/LibraryFiles/article_list.json?request='+request };
    }
    requests.push(request);
  }

  let dfds = [];
  dfds.push($.getJSON('/LibraryFiles/contentslink/genre.json'));
  for ( let i = 0; i < requests.length; i++ ) {
    req = requests[i];
    dfd = $.getJSON(req.path);
    dfds.push(dfd);
  }

  let getJson = $.when.apply($, dfds);
  getJson.done( (genres,result0,result1,result2,result3,result4,result5,result6,result7,result8,result9,result10,result11,result12) => {

    if ( typeof result13 === "object" ) {
      var json = [...result0[0]['list'],...result1[0]['list'],...result2[0]['list'],...result3[0]['list'],...result4[0]['list'],...result5[0]['list'],...result6[0]['list'],...result7[0]['list'],...result8[0]['list'],...result9[0]['list'],...result10[0]['list'],...result11[0]['list'],...result12[0]['list'],...result13[0]['list']];
    } else if ( typeof result12 === "object" ) {
      var json = [...result0[0]['list'],...result1[0]['list'],...result2[0]['list'],...result3[0]['list'],...result4[0]['list'],...result5[0]['list'],...result6[0]['list'],...result7[0]['list'],...result8[0]['list'],...result9[0]['list'],...result10[0]['list'],...result11[0]['list'],...result12[0]['list']];
    } else if ( typeof result11 === "object" ) {
      var json = [...result0[0]['list'],...result1[0]['list'],...result2[0]['list'],...result3[0]['list'],...result4[0]['list'],...result5[0]['list'],...result6[0]['list'],...result7[0]['list'],...result8[0]['list'],...result9[0]['list'],...result10[0]['list'],...result11[0]['list']];
    } else if ( typeof result10 === "object" ) {
      var json = [...result0[0]['list'],...result1[0]['list'],...result2[0]['list'],...result3[0]['list'],...result4[0]['list'],...result5[0]['list'],...result6[0]['list'],...result7[0]['list'],...result8[0]['list'],...result9[0]['list'],...result10[0]['list']];
    } else if ( typeof result9 === "object" ) {
      var json = [...result0[0]['list'],...result1[0]['list'],...result2[0]['list'],...result3[0]['list'],...result4[0]['list'],...result5[0]['list'],...result6[0]['list'],...result7[0]['list'],...result8[0]['list'],...result9[0]['list']];
    } else if ( typeof result8 === "object" ) {
      var json = [...result0[0]['list'],...result1[0]['list'],...result2[0]['list'],...result3[0]['list'],...result4[0]['list'],...result5[0]['list'],...result6[0]['list'],...result7[0]['list'],...result8[0]['list']];
    } else if ( typeof result7 === "object" ) {
      var json = [...result0[0]['list'],...result1[0]['list'],...result2[0]['list'],...result3[0]['list'],...result4[0]['list'],...result5[0]['list'],...result6[0]['list'],...result7[0]['list']];
    } else if ( typeof result6 === "object" ) {
      var json = [...result0[0]['list'],...result1[0]['list'],...result2[0]['list'],...result3[0]['list'],...result4[0]['list'],...result5[0]['list'],...result6[0]['list']];
    } else if ( typeof result5 === "object" ) {
      var json = [...result0[0]['list'],...result1[0]['list'],...result2[0]['list'],...result3[0]['list'],...result4[0]['list'],...result5[0]['list']];
    } else if ( typeof result4 === "object" ) {
      var json = [...result0[0]['list'],...result1[0]['list'],...result2[0]['list'],...result3[0]['list'],...result4[0]['list']];
    } else if ( typeof result3 === "object" ) {
      var json = [...result0[0]['list'],...result1[0]['list'],...result2[0]['list'],...result3[0]['list']];
    } else if ( typeof result1 === 'object' && typeof result2 === "object" ) {
      var json = [...result0[0]['list'],...result1[0]['list'],...result2[0]['list']];
    } else if ( typeof result0 === 'object' && typeof result1 === "object" ) {
      var json = [...result0[0]['list'],...result1[0]['list']];
    } else if ( typeof result0 === 'object' ) {
      var json = result0[0]['list'];
    }

    if ( typeof result1 !== 'undefined' ) {
      json.sort( (a, b) => {
        if (new Date(((a.dateRelease) ? a.dateRelease.all : a.dateDelivery_all)) > new Date(((b.dateRelease) ? b.dateRelease.all : b.dateDelivery_all))) {
          return -1;
        }
        if (new Date(((a.dateRelease) ? a.dateRelease.all : a.dateDelivery_all)) < new Date(((b.dateRelease) ? b.dateRelease.all : b.dateDelivery_all))) {
          return 1;
        }
        return 0;
      });
    }

    var _listlen = json.length;

    let i = 0;
    let j = 0;
    let _html = "";

    let listType;
    let deviceType;
    let featureType;
    let speType;
    let searchClass = $('.article-list[data-targets="'+_targets+'"][data-list-id="'+_listID+'"]').prop('class').split(' ');
    searchClass.filter(function(filterType){
      if( filterType.indexOf('type') === 0 ){
        listType = filterType;
      }
      if( filterType.indexOf('feature1st') === 0 ){
        featureType = filterType;
      }
      if( filterType.indexOf('hasToday') === 0 ){
        speType = filterType;
      }
      if( filterType.indexOf('ads-') === 0 ){
        deviceType = filterType;
      }
    });

    $.each(json, (r, item) => {

      if ( j > _offset - 1 ) {
        if ( i > _num - 1 ) { return false; }

        // infeed広告枠作成
        if ( _insertAD ) {
          $.each(_insertAD.split(','), function(){
            if ( i === (Number(this) - 1) ) {
              _html += `<li class="isInfeed hideloggedin"></li>`;
            }
          });
        }

        // もっと見る押下時 追加表示一番目をマーク
        $('.currentPos').removeClass('currentPos');
        var _currentPos = ( i == 0 ) ? 'currentPos' : '';

        var _url = item.url.replace('sp/', ''),
            _title = ((item.dateRelease) ? item.title.trim() : item.title.trim().slice(0, 50)),
            _body = ((item.dateRelease) ? item.body : item.body.replace(/(<([^>]+)>)/gi, '')),
            _genre = ((item.genre) ?  item.genre : ''),
            _pay = item.pay,
            _important = item.important,
            _d_MM = ((item.dateRelease) ? item.dateRelease.MM.replace(/^0+/, '') : item.dateDelivery_MM.replace(/^0+/, '')),
            _d_dd = ((item.dateRelease) ? item.dateRelease.dd.replace(/^0+/, '') : item.dateDelivery_dd.replace(/^0+/, '')),
            _d_HH = ((item.dateRelease) ? item.dateRelease.HH : item.dateDelivery_HH),
            _d_mm = ((item.dateRelease) ? item.dateRelease.mm : item.dateDelivery_mm),
            _d_all = ((item.dateRelease) ? item.dateRelease.all : item.dateDelivery_all),
            _movie = ((item.movie) ? item.movie : '');
        var _category = [];
        if (_genre) {
          if (Array.isArray(_genre)) {
            arraygenre = _genre;
          } else {
            arraygenre = _genre.split('＠＠');
            arraygenre.splice(0, 1);
          }
          arraygenre.forEach(function(item){
            for (let i = 0; i < genres[0][0]['genre_item'].length; i++) {
              if (item === genres[0][0]['genre_item'][i]) {
                _category[item] = genres[0][0]['genre_path'][i];
              }
            }
          });
        }
        if (item.dateRelease) {
          if ( item.photo.length > 0 ) {
            var _filepath = item.photo[0].filepath,
                _filename = item.photo[0].filename,
                _height = item.photo[0].height,
                _width = item.photo[0].width,
                _date = item.photo[0].date,
                _phototext = item.photo[0].phototext;
          }
        } else {
          if (item.photo.filepath) {
            var _filepath  = item.photo.filepath,
                _filename  = item.photo.filename.replace('d_', ''),
                _height    = item.photo.height,
                _width     = item.photo.width,
                _date      = item.photo.date,
                _phototext = item.photo.phototext;
          }
        }
        _body = _body.replace('\\n\\n', '');
        _thumbpath = (_filepath && _filename) ? `//i.kobe-np.co.jp${_filepath}${_imgPattern}_${_filename}` : '/assets/img/nophoto-next.png';
        _ico = `<span class="ic pay"></span><span class="ic pho"></span><span class="ic mov"></span>`;
        if (_pay === '1' && _important  === '0') {
          _pay = '2';
        } else if (_pay === '0' && _important === '1') {
          _pay = '2';
        } else if (_pay === '1' && _important === '1') {
          _pay = '1';
        } else {
          _pay = '0';
        }

        if ( speType === 'hasToday' ) {
          _html += `<li class="${_currentPos}" style="height:calc(var(--todays-item-H) * 2);min-height:calc(var(--todays-item-H) * 2)">`;
          // _html += `<li class="${_currentPos}">`;

        } else {
          _html += `<li data-pay="${_pay}" data-photo="0" data-mov="${_movie}" class="${_currentPos}">`;
        }
        _html += `<a href="${_url}" class="thumb">`;
        _html += `<img src="${_thumbpath}" width="${_width}" height="${_height}" decoding="async" loading="lazy">`;
        _html += `</a>`;

        _html += `<div class="detail">`;

        if( listType === 'type06' ) {
          _html += `<a href="${_url}" class="caption">${_title} ${_ico}</a>`;
        } else {
          _html += `<a href="${_url}" class="caption">${_title}</a>`;
        }

        _html += `<div class="pubmeta">`;
        if ( (location.pathname.indexOf('rentoku/photo') === -1) && ( listType !== 'type06' ) ) {
          _html += `<time class="meta-time">${_d_MM}/${_d_dd} ${_d_HH}:${_d_mm}</time>`;
        }

        if( listType === 'type05' ) {
          _html += `<p class="meta-caption"></p>`;
        }

        _html += _ico;

        if ( ( _category ) && ( listType != 'type02' ) ) {
          for (let key in _category) {
            _html += `<a href="/${_category[key]}" class="meta-cat">${[key]}</a>`;
          }
        }
        _html += `</div>`;

        if ( ( _category ) && ( listType === 'type02' ) ) {
          _html += `<div class="pubmeta">`;
          for (let key in _category) {
            _html += `<a href="/${_category[key]}" class="meta-cat">${[key]}</a>`;
          }
          _html += `</div>`;
        }

        if ( ( featureType === 'feature1st' )  && ( i === 0) ) {
          _html += `<p class="excerpt">${_body}</p>`;
        //} else if(speType === 'hasToday') {
        //  _html += `<!-- <p class="excerpt">${_body}</p> -->`;
        } else {
          _html += `<!-- <p class="excerpt">${_body}</p> -->`;
        }
        _html += `</div>`;
        _html += `</li>`;

        i++;

      } // $.each(json)

      j++;
    });

    let _target_list = $('.article-list[data-list-id="'+_listID+'"]');
    let _now = Number(_num) + Number(_num * (_offset / _num));

    if ( (Number(_now) + Number(_num)) > _max_more + (Number(_num)) ) {
      if( _num > _listlen ) {
        _target_list.append(_html);
      } else {
        _target_list.next('.btn-wrap').find('.btn').remove();
      }
    } else {
      if ( _now >= _listlen ) {
        _target_list.append(_html);
        _target_list.next('.btn-wrap').find('.btn').remove();
      } else {
        _target_list.append(_html);
      }

    }

    $('.article-list[data-targets="'+_targets+'"][data-list-id="'+_listID+'"] .isInfeed').not(".isInfeedLoad").each( (i, inf) => {
      // 子要素が広告タグがあるので追加しない
      if(inf.childElementCount > 0) {
        return
      }

      // 親要素が非表示の時はタグを読まない
      if($(inf).parent().parent().css("display") == 'none'){
        return
      }

      // 読込済みのクラス追加
      $(inf).addClass("isInfeedLoad")

      if ( $(inf).parent().hasClass('ads-sp') ) {
        $(inf).load('/assets/js/infeed_sp.html');
      } else {
        $(inf).load('/assets/js/infeed_pc.html');
      }
    });
    $('.login .isInfeed').remove();

  }).fail(function(){
    console.log('multi fail')
  });
}
